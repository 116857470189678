<template>
  <div class="content-wrapper">
    <div class="content-lookup">  <!-- 필터 -->
      <div class="lookup-left">
        <ul class="lookup-condition"> <!-- 범위 날짜 검색 -->
          <li class="field">
            <div class="title">사용월</div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref = "yearMonth"
                    :format="DATE_FORMAT_YYYY_MM"
                    :notClear="true"
                    depth="Year"
                    v-model="searchFilterOptions.yearMonth"
                    type="lookup-condition"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">유류구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    ref="searchEqpFuel"
                    v-model="searchFilterOptions.fuelCode"
                    :dataSource="commonCodeOptions.fuelCode"
                    :allowFiltering="false"
                    :fields="commonCodeOptions.fields"
                    cssClass="lookup-condition-dropdown"
                >
                </ejs-dropdownlist>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET"  :ignore="isPopupOpened" :is-shortcut-button="true" @click.native="onClickSearch()">조회</erp-button>
        </div>
      </div>
      <div class="lookup-right"> <!-- 사용시 좌측이 없을 경우 생략-->
        <ul class="lookup-button">
        </ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">{{ gridField.title }}</div>
              <div class="header-caption">[{{ gridField.count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  v-bind="initGrid"
                  @actionComplete="gridComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <!-- 각종팝업 -->
  </div>
</template>
<style scoped>

</style>

<script>

import InputDate from "@/components/common/datetime/InputDate.vue";
import {DATE_FORMAT_YYYY_MM, getFormattedDate,} from "@/utils/date";
import ErpButton from "@/components/button/ErpButton.vue";
import moment from "moment/moment";
import StockEquipmentStat from "@/api/v2/StockManage/StockEquipmentStat";
import {mapGetters} from "vuex";
import {Aggregate, Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import StockCommon from "@/api/v2/StockManage/StockCommon";

export default {
  name: "OilUseStatus",
  components: {ejsGridWrapper, ErpButton, InputDate},
  created() {},
  mounted() {
    this.initData();
  },
  data() {
    return {
      DATE_FORMAT_YYYY_MM,
      searchFilterOptions: {
        yearMonth: null,
        fuelCode: null,
      },
      commonCodeOptions: {
        fuelCode: [],
        fields: { text: "comName", value: "comCode" },
      },
      gridField: {
        title: "유류 사용 목록",
        count: 0,
        dataSource: [],
      },
    };
  },
  computed: {
    ...mapGetters(["userIp","userId","username"]),
    isPopupOpened() {
      return false; // TODO : 팝업 있을 경우, 삽입
    },
    initGrid() {
      return {
        columns: [
          {field: 'eqpDiv',       textAlign: 'center', width: 100, visible: true,   allowEditing : false,  type: 'string', headerText: '장비구분', isCommonCodeField: true, groupCode: "EQP_DIV"},
          {field: 'eqpName',      textAlign: 'center', width: 200, visible: true,   allowEditing : false,  type: 'string', headerText: '장비명',},
          {field: 'total',        textAlign: 'right',  width: 60,  visible: true,   allowEditing : false,  type: 'number', headerText: '합계', isNumericType: true,  },
          {field: 'day01',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '1',    isNumericType: true,  },
          {field: 'day02',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '2',    isNumericType: true,  },
          {field: 'day03',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '3',    isNumericType: true,  },
          {field: 'day04',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '4',    isNumericType: true,  },
          {field: 'day05',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '5',    isNumericType: true,  },
          {field: 'day06',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '6',    isNumericType: true,  },
          {field: 'day07',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '7',    isNumericType: true,  },
          {field: 'day08',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '8',    isNumericType: true,  },
          {field: 'day09',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '9',    isNumericType: true,  },
          {field: 'day10',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '10',   isNumericType: true,  },
          {field: 'day11',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '11',   isNumericType: true,  },
          {field: 'day12',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '12',   isNumericType: true,  },
          {field: 'day13',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '13',   isNumericType: true,  },
          {field: 'day14',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '14',   isNumericType: true,  },
          {field: 'day15',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '15',   isNumericType: true,  },
          {field: 'day16',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '16',   isNumericType: true,  },
          {field: 'day17',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '17',   isNumericType: true,  },
          {field: 'day18',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '18',   isNumericType: true,  },
          {field: 'day19',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '19',   isNumericType: true,  },
          {field: 'day20',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '20',   isNumericType: true,  },
          {field: 'day21',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '21',   isNumericType: true,  },
          {field: 'day22',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '22',   isNumericType: true,  },
          {field: 'day23',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '23',   isNumericType: true,  },
          {field: 'day24',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '24',   isNumericType: true,  },
          {field: 'day25',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '25',   isNumericType: true,  },
          {field: 'day26',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '26',   isNumericType: true,  },
          {field: 'day27',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '27',   isNumericType: true,  },
          {field: 'day28',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '28',   isNumericType: true,  },
          {field: 'day29',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '29',   isNumericType: true,  },
          {field: 'day30',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '30',   isNumericType: true,  },
          {field: 'day31',        textAlign: 'right',  width: 40,  visible: true,   allowEditing : false,  type: 'number', headerText: '31',   isNumericType: true,  },
        ],
        provides: [Aggregate,ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: false,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay: true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: {pageSize: 50},
        aggregates:[
          {
            columns: [
              {field: 'eqpName', aggregationType: 'TotalCaption', customAggregate: '합계',},
              {field: 'total',       aggregationType: 'TotalSum',},
              {field: 'day01',       aggregationType: 'TotalSum',},
              {field: 'day02',       aggregationType: 'TotalSum',},
              {field: 'day03',       aggregationType: 'TotalSum',},
              {field: 'day04',       aggregationType: 'TotalSum',},
              {field: 'day05',       aggregationType: 'TotalSum',},
              {field: 'day06',       aggregationType: 'TotalSum',},
              {field: 'day07',       aggregationType: 'TotalSum',},
              {field: 'day08',       aggregationType: 'TotalSum',},
              {field: 'day09',       aggregationType: 'TotalSum',},
              {field: 'day10',       aggregationType: 'TotalSum',},
              {field: 'day11',       aggregationType: 'TotalSum',},
              {field: 'day12',       aggregationType: 'TotalSum',},
              {field: 'day13',       aggregationType: 'TotalSum',},
              {field: 'day14',       aggregationType: 'TotalSum',},
              {field: 'day15',       aggregationType: 'TotalSum',},
              {field: 'day16',       aggregationType: 'TotalSum',},
              {field: 'day17',       aggregationType: 'TotalSum',},
              {field: 'day18',       aggregationType: 'TotalSum',},
              {field: 'day19',       aggregationType: 'TotalSum',},
              {field: 'day20',       aggregationType: 'TotalSum',},
              {field: 'day21',       aggregationType: 'TotalSum',},
              {field: 'day22',       aggregationType: 'TotalSum',},
              {field: 'day23',       aggregationType: 'TotalSum',},
              {field: 'day24',       aggregationType: 'TotalSum',},
              {field: 'day25',       aggregationType: 'TotalSum',},
              {field: 'day26',       aggregationType: 'TotalSum',},
              {field: 'day27',       aggregationType: 'TotalSum',},
              {field: 'day28',       aggregationType: 'TotalSum',},
              {field: 'day29',       aggregationType: 'TotalSum',},
              {field: 'day30',       aggregationType: 'TotalSum',},
              {field: 'day31',       aggregationType: 'TotalSum',},
            ],
          },
        ],
      };
    },
  },
  methods: {
    //초기화
    async initData() {
      this.commonCodeOptions.fuelCode = await StockCommon.getOilSupplies({useFlag: null});
      this.searchFilterOptions.yearMonth = moment().format(DATE_FORMAT_YYYY_MM);
      this.searchFilterOptions.fuelCode = await this.commonCodeOptions.fuelCode[0].comCode;


    },
    //region ============================ API ==========================================
    async apiGetList(){

      this.gridField.dataSource = await StockEquipmentStat.getOilUseStatus(this.searchFilterOptions);
      // 달력에 없는 날짜 컬럼은 가리자... 이상하게 setTimeout function으로 감싸야 적용됨..
      setTimeout(() => {
        let lastDay = moment(getFormattedDate(this.searchFilterOptions.yearMonth)).endOf('month').format('DD');
        for(lastDay++;lastDay<=31;lastDay++){
          this.$refs.grid.hideColumns(`${lastDay}`);
        }
      }, 0);
    },
    //endregion ========================================================================
    //region ============================ Button Click ==========================================
    onClickSearch(){
        this.apiGetList();
    },
    onClickExcel(){
       this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    //endregion ========================================================================
    //region ============================ 그리드 ==========================================
    //그리드 조회 완료시(카운트)
    gridComplete() {
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    //endregion ========================================================================
  },

};
</script>